import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '推广中心'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/groupinfo',
    name: 'GroupInfo',
    component: () => import('../views/GroupInfo.vue'),
    meta: {
      title: '推广组信息'
    }
  },
  {
    path: '/memberlist',
    name: 'MemberList',
    component: () => import('../views/MemberList.vue'),
    meta: {
      title: '成员列表'
    }
  },
  {
    path: '/twomemberlist',
    name: 'twoMemberList',
    component: () => import('../views/twoMemberList.vue'),
    meta: {
      title: '二级推广组列表'
    }
  },
  {
    path: '/income',
    name: 'Income',
    component: () => import('../views/Income.vue'),
    meta: {
      title: '收益流水'
    }
  }, {
    path: '/DataView',
    name: 'DataView',
    component: () => import('../views/DataView.vue'),
    meta: {
      title: '收益流水'
    }
  },
  {
    path: '/twolevel',
    name: 'twolevel',
    component: () => import('../views/twolevel.vue'),
    meta: {
      title: '二级收益流水'
    }
  },
  {
    path: '/comission',
    name: 'Comission',
    component: () => import('../views/Comission.vue'),
    meta: {
      title: '分成统计'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {
  if (!localStorage.getItem("token") && to.name !== 'Login' && to.name !== 'Home') {
    return { name: 'Home' }
  }
})

router.afterEach(() => {
  if (!window.sessionStorage.firstUrl) {
    window.sessionStorage.firstUrl = window.location.href
  }
})

export default router
