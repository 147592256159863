// 导入axios
import axios from 'axios'
import Router from '@/router/index'
import { Toast } from 'vant';
import qs from 'qs'
// let http = 'https://h5.zhihexuntech.com'

function post(url, data) {
  return new Promise((resolve, reject) => {
    let token = ''
    if (localStorage.getItem('token')) {
      token = localStorage.getItem('token')
    }
    axios.post(url, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'timeStamp': new Date().getTime(),
        'machineCode': 'test',
        'terminal': 'PC',
        'versionCode': '1.0.0',
        'channelCode': '000000',
        'authorization': token
      },
    }).then((res) => {
      resolve(res)
      if (res.data.code == 700 || res.data.code == 701) {
        Toast('登录已过期')
        Router.replace({ path: 'Login' })
      } else if (res.data.code !== 0) {
        Toast(res.data.message)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

function postJson(url, data) {
  return new Promise((resolve, reject) => {
    let token = ''
    if (localStorage.getItem('token')) {
      token = localStorage.getItem('token')
    }
    axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
        'timeStamp': new Date().getTime(),
        'machineCode': 'test',
        'terminal': 'PC',
        'versionCode': '1.0.0',
        'channelCode': '000000',
        'authorization': token
      },
    }).then((res) => {
      resolve(res)
      if (res.data.code == 700 || res.data.code == 701) {
        Toast('登录已过期')
        Router.replace({ path: 'Login' })
      } else if (res.data.code !== 0) {
        Toast(res.data.message)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

//4.导入文件
export default {
  post,
  postJson
}
