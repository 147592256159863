<template>
    <div class="marke">
        <img src="../assets/image/mask.png" alt="">
    </div>
</template>
<script>
    export default {
        name: 'Mask',
        data(){
            return{}
        },
    }
</script>
<style lang="less" scoped>
    .marke{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        background-color: rgba(0,0,0,0.6);
        text-align: center;
        img{
            width: 90%;
            margin: 60px auto;
        }
    }
</style>