<template>
  <div class="box">
    <div class="title">
      <div class="images">
        <img :src="
          !issin
            ? muser
            : userinfo.portrait
              ? config.fileServerUrl + userinfo.portrait
              : muser
        " alt="" />
      </div>

      <div class="btn" v-if="!issin">
        <span style="color: #11e011;font-size: 14px;" @click="toJump('Login')">点击登录</span>
      </div>
      <div class="names">
        <p class="nickname font2 f_color1">
          {{ issin ? userinfo.nickName : "" }}
        </p>
        <p class="userid font1 f_color2">
          {{
          issin
          ? `ID:${userinfo.chatNo ? userinfo.chatNo : userinfo.userId}`
          : ""
          }}
        </p>
      </div>
      <div class="btn" style="margin-left: 20px" v-if="issin">
        <span style="color: red;font-size: 14px;position: absolute;right: 20px;" @click="toDel('Login')">退出登录</span>
      </div>
    </div>
    <div v-if="payGroupInfo" class="content font2">
      <div class="listItem lines_bottom" @click="toJump(item.path)" v-for="(item, i) in (payGroupInfo.type === 0 ? list : listRecharge)" :key="i">
        {{ item.name }}
        <img src="@/assets/image/right.png" alt="" />
      </div>
    </div>
    <Mask v-if="marke"></Mask>
  </div>
</template>
<script>
import muser from "@/assets/image/user.png";
// import { wxqq } from "../utils/utils";
import Mask from "../components/Mask";
import { Toast } from 'vant';

export default {
  name: "Home",
  data() {
    return {
      list: [
        {
          name: "推广组信息",
          path: "GroupInfo",
        },
        {
          name: "成员列表",
          path: "MemberList",
        },
        {
          name: "收益流水",
          path: "Income",
        }, {
          name: "数据概览",
          path: "DataView",
        },
        {
          name: "二级推广组列表",
          path: "twoMemberList",
        },
        {
          name: "二级收益流水",
          path: "twolevel",
        }
     

      ],
      listRecharge: [
        {
          name: "推广组信息",
          path: "GroupInfo",
        },
        {
          name: "成员列表",
          path: "MemberList",
        },
        {
          name: "充值流水",
          path: "Income",
        },
      ],
      issin: false,
      userinfo: {
        nickName: "",
        userId: "",
        portrait: "",
        isCertified: false,
      },
      payGroupInfo: null,
      muser,
      marke: false,
    };
  },
  components: {
    Mask,
  },
  computed: {
    config: () => {
      return JSON.parse(localStorage.getItem("config"))
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      this.issin = true
      this.getuserinfo()
      this.getPayGroupInfo()
    } else {
      this.$router.push({ path: "Login" })
    }
  },
  mounted() {
    // this.marke = wxqq();
  },
  methods: {
    toJump(path) {
      if (!this.issin) {
        return this.$router.push({ path: "Login" })
      }
      this.$router.push({ path: path })
    },
    toDel() {
      this.$request.post("/api/user/passport/logout").then(() => {
        localStorage.setItem("token", "")
        this.issin = false
        this.$router.push({ path: "Login" })
      });
    },
    getuserinfo() {
      this.$request.post("/api/user/mine/userInfo").then((res) => {
        if (res.data.code == 0) {
          if (res.data.data) {
            this.userinfo = res.data.data
          }
        }
      });
    },
    getPayGroupInfo() {
      this.$request.postJson('/api/user/pay_group/info', {}).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data) {
            this.payGroupInfo = res.data.data
          } else {
            Toast('未查询到推广组信息')
            this.toDel()
          }
        }
      })
    },
  },
};
</script>
<style scoped lang="less">
.box {
  height: 100%;
  background-color: #f5f5f5;

  .title {
    width: 100%;
    height: 52px;
    padding: 20px 0;
    background-color: #fff;
    display: flex;

    .images {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      margin-left: 20px;
      margin-right: 12px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .btn {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    .names {
      p {
        height: 20px;
        line-height: 20px;
      }

      .nickname {
        margin-top: 6px;
      }
    }
  }
}

.content {
  width: 100%;
  background-color: #fff;
  margin-top: 10px;

  .listItem {
    width: 100%;
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    box-sizing: border-box;

    img {
      width: 6px;
      height: 12px;
      float: right;
      margin-top: 21px;
    }
  }
}
</style>
